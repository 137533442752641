.footer {
    display: flex;
    flex-direction: column;
}

.footer h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3C3E3F;
}

.footer p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    margin: 12px 0px;
    color: #3C3E3F;
}

.footer h6 {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #3C3E3F;
}

.links {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
}