@media only screen and (max-width: 912px) {
  .subscription {
    display: flex;
    margin-bottom: 22px;
    flex-direction: column;
  }

  .logo {
    width: 25%;
  }

  .logo img {
    width: 100%;
  }

  .carType {
    display: flex;
    padding: 0px 10px;
  }

  .vehicleDetails {
    display: flex;
    flex-direction: column;
    background-color: #f3f3f3;
    padding: 12px;
    border-radius: 8px;
  }

  .vehicleDetails div {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .vehicleDetails div h4 {
    font-size: 20px;
  }

  .vehicleDetails div p {
    font-size: 12px;
  }

  .carType div p {
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
  }

  .subscription__body {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
  }

  .subscription__bodyInner {
    background-color: #ffffff;
    margin: 12px 12px;
  }

  .subscription__wrapper {
    display: flex;
    width: 100%;
  }

  .subscription__wrapper img {
    width: 100%;
    height: 100%;
  }
  .diagnose {
    margin: 12px;
  }
  .diagnose h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: 18px 0px;
    color: #3c3e3f;
  }
  .diagnose p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #3c3e3f;
  }
  .diagnose h5 {
    margin: 18px 0px;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #3c3e3f;
  }
  .diagnose__wrapper {
    padding: 12px;
    background-color: #f3f3f3;
  }
  .diagnose__wrapper__inner {
    display: flex;
    margin: 5px 0px;
    justify-content: space-between;
  }
  .diagnose__wrapper__inner div p {
    padding: 12px 8px;
    overflow: hidden;
  }
  .error {
    background-color: white;
    overflow: hidden;
    display: flex;
    flex: 1;
    max-width: 80%;
    width: 90%;
  }
  .error p {
    font-size: 12px;
  }
  .diagnose__wrapper__inner div a {
    text-decoration: none;
  }
  .diagnose__wrapper__inner div a p {
    background-color: #ffffff;
    color: #4fb128;
  }
}
