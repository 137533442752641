@media only screen and (max-width: 912px) {
    .navBar {
        background-color: #FFFFFF;
        align-items: center;
        width: 100%;
        display: flex;
        position: fixed;
        z-index: 10000;
    }

    .logo {
        align-items: center;
        display: flex;
    }

    .logo div{
        flex: 1;
    }

    .logo p {
        font-weight: 500;
        font-size: 16px;
        margin-left: 12px;
        line-height: 20px;
        color: #3C3E3F;
    }

    .navBar__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 19px;
        width: 100%;
    }

    .navBar__hamburger {
        cursor: pointer;
        /* background-color: green; */
    }

    .navBar__hamburger div {
        background-color: #3C3E3F;
        height: 2px;
        margin-top: 4px;
        width: 16px;
    }

    .navBar__title {
        display: flex;
        margin-left: 12px;
        align-items: center;
        text-align: center;
    }

    .navBar__title p {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
    }

    .navBar__notifications {
        display: flex;
        justify-content: space-between;
    }
    .wrapper{
        width: 25%;
    }
    .wrapper img{
        width: 40%;
    }
}

@media only screen and (max-width: 540px) {
    .navBar {
        background-color: #FFFFFF;
        align-items: center;
        width: 100%;
        display: flex;
        position: fixed;
        z-index: 10000;
    }

    .logo {
        align-items: center;
        display: flex;
        flex: 1;
        max-width: 40%;
    }

    .logo div{
        flex: 1;
    }

    .logo p {
        font-weight: 500;
        font-size: 16px;
        margin-left: 12px;
        line-height: 20px;
        color: #3C3E3F;
    }

    .navBar__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 19px;
        width: 100%;
    }

    .navBar__hamburger {
        cursor: pointer;
        /* background-color: green; */
    }

    .navBar__hamburger div {
        background-color: #3C3E3F;
        height: 2px;
        margin-top: 4px;
        width: 16px;
    }

    .navBar__title {
        display: flex;
        margin-left: 12px;
        align-items: center;
        text-align: center;
    }

    .navBar__title p {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
    }

    .navBar__notifications {
        display: flex;
        /* flex: 1; */
        /* max-width: 60%; */
        justify-content: space-between;
    }
    .wrapper{
        width: 25%;
    }
    .wrapper img{
        width: 100%;
    }
}