.Livemetrics {
  display: flex;
  flex-direction: column;
  margin: 20px 5px;
}
.Livemetrics__inner {
  display: flex;
  justify-content: space-between;
}
.Livemetrics__inner__content {
  width: 45%;
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 12px 0px;
  justify-content: flex-end;
  background: #f7f7f7;
  height: 200px;
  border-radius: 12px 12px 0px 0px;
}
.fuelGuage_measurment {
  width: 100%;
  transition: all 0.3s ease-out;
  height: 50%;
  border-radius: 0px 0px 8px 8px;
}
.Livemetrics__inner__content__inner {
  z-index: 1000;
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.guage {
  width: 100%;
  height: 70px;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
