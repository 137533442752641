.App {
  display: none;
}

.menu{
  z-index: 100000;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.606);
}

@media only screen and (max-width: 912px) {
  .App {
    display: flex;
    flex-direction: column;
  }
}
