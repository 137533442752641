@media only screen and (max-width: 912px) {
    .wrapper {
        display: flex;
        padding: 12px;
    }

    .carousel {
        margin: 0px 20px;
        margin-top: 10px;
    }

    .wrapper__inner {
        display: flex;
        flex: 1;
        max-width: 40%;
        width: 40%;
    }

    .wrapper__inner img {
        width: 100%;
        height: 100%;
    }

    .wrapper .text {
        display: flex;
        flex: 1;
        max-width: 70%;
        flex-direction: column;
    }

    .wrapper .text h4 {
        font-size: 14px;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .wrapper .text p {
        color: #BABABA;
        font-size: 12px;
        margin-left: 15px;
    }

    .wrapper .text2 {
        padding: 20px;
        background: rgba(135, 232, 130, 0.15);
        border-radius: 12px;
    }

    .wrapper .text2 h4 {
        font-size: 14px;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .wrapper .text2 p {
        color: #3C3E3F;
        font-size: 12px;
        margin-left: 15px;
    }

    .wrapper .text2 div {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
    }

    .wrapper .text2 div p {
        color: #008069;
    }

    .wrapper .text3 {
        padding: 20px;
        background: rgb(229, 226, 193);
        border-radius: 12px;
    }

    .wrapper .text3 h4 {
        font-size: 14px;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    .wrapper .text3 p {
        color: #3C3E3F;
        font-size: 12px;
        margin-left: 15px;
    }

    .wrapper .text3 div {
        margin-top: 12px;
        display: flex;
        justify-content: flex-end;
    }

    .wrapper .text3 div p {
        color: #008069;
    }

}