.report {
    margin: 16px 10px;
}

.customer_details {
    display: flex;
    flex-direction: column;
    background-color: #E2FBD7;
    padding: 20px 15px;
    margin-top: 100px;
}

.customer_details h4 {
    font-style: normal;
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 20px;
    color: #3C3E3F;
}

.contact {
    background-color: #E2FBD7;
    padding: 20px;
}

.customer_details p {
    font-weight: 450;
    font-size: 12px;
    margin: 12px 0px;
    line-height: 15px;
    color: #3C3E3F;
}

.subscription__bodyInner {
    background-color: #ffffff;
    margin: 12px 12px;
}

.subscription__wrapper {
    display: flex;
    width: 100%;
}

.subscription__wrapper img {
    width: 100%;
    height: 100%;
}

.subscription__body {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
}

.logo {
    width: 10%;
}

.logo img {
    width: 100%;
}

.vehicleDetails {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 8px;
}

.vehicleDetails div {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

.vehicleDetails div h4 {
    font-size: 20px;
}

.vehicleDetails div p {
    font-size: 12px;
}

.vehicleDetails_2 div {
    background-color: #F7F7F7;
    padding: 15px 10px;
}

.vehicleDetails_2 p {
    gap: 10;
    margin: 10px 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.carStatus {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    margin: 30px 5px;
    padding: 10px 10px;
}

.carStatus__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
}

.carStatus__inner2 {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    height: 100%;
    justify-content: space-between;
}

.carStatus__inner2 p {
    display: flex;
    align-items: center;
}

.carStatus__inner2 p span {
    margin-right: 4px;
}

.carStatus__inner h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #263238;
}

.carStatus__inner2__title {
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    flex: 1;
    max-width: 70%;
    display: flex;
    background-color: #ffffff;
    color: #263238;
}

.carStatus__inner2__title__head {
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    flex: 1;
    max-width: 70%;
    flex-direction: column;
    display: flex;
    background-color: #ffffff;
    color: #263238;
}

.carStatus__inner2__status__head {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    width: 30%;
    height: 100%;
    align-items: center;
    background-color: #FFF5CC;
    padding: 10px 0px;
    position: relative;
    color: #FF9900;
}

.carStatus__inner2__status {
    font-weight: 500;
    position: relative;
    font-size: 14px;
    line-height: 18px;
    width: 30%;
    display: flex;
    height: 100%;
    align-items: center;
    background-color: #FFF5CC;
    padding: 10px 20px;
    text-align: center;
    color: #FF9900;
}

.map__wrapper {
    width: 100%;
    position: relative;
    background-color: #ffffff;
}

.errInfo {
    background-color: white;
    margin: 10px 5px;
}

.errInfo p {
    font-weight: 450;
    margin: 10px 0px;
    font-size: 14px;
    line-height: 30px;
    display: flex;
    align-items: center;
}

.err {
    color: #FD4D1E;
    margin: 10px 0px;
}

.buttons {
    display: flex;
    flex-direction: column;
}

.buttons a {
    text-decoration: none;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
}

.warning {
    margin-top: 30px;
    font-weight: 500;
}

.warning p {
    font-weight: 450;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
}

.button__inner {
    display: flex;
    align-items: center;
}
.button__inner2 {
    display: flex;
    align-items: center;
}

.button__inner p {
    margin-left: 90px;
}

.button__inner svg {
    margin-right: 10px;
}
.button__inner2 p {
    margin-left: 120px;
}

.button__inner svg {
    margin-right: 20px;
}