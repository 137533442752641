.form {
  margin: 30px 5px;
}
.form p {
  text-align: center;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3c3e3f;
}
.toast{
  z-index: 10000000000;
}
