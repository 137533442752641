.menu__header {
    z-index: 10000;
    width: 50%;
    position: absolute;
}

.menu__modal__inner {
    height: 20vh;
    background-color: white;
    width: 60%;
    color: white;
}

.modal__header {
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(186.9deg, #008069 26.79%, #35D853 113.67%);
}

.wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(186.9deg, #008069 26.79%, #35D853 113.67%);
}

.wrapper img {
    width: 100%;
}

.modal__header div {
    align-items: center;
    margin-top: 33px;
    margin-bottom: 35px;
    margin-left: 12px;
}

.menu__modal__inner h4 {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
}

.menu__modal__inner p {
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
}

.menu__modal__list {
    color: #3C3E3F;
    margin-top: 43px;
    height: 60vh;
    overflow: auto;
}

.menu__modal__list div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.menu__modal__list p {
    font-weight: 450;
    font-size: 18px;
    line-height: 18px;
}

.svg {
    height: 100%;
    width: 55px;
    margin-left: 18px;
}

.title {
    margin-left: 1px;
    display: flex;
}