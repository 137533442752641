@media only screen and (max-width: 912px) {
  .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .actions button {
    cursor: pointer;
    color: white;
    background-color: #4fb128;
    border: 1px solid #4fb128;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    width: 100%;
    height: 44px;
    transition: all 0.2s ease-in-out;
    padding: 1.2rem, 1.6rem, 1.2rem, 1.6rem;
  }

  .actions button:hover {
    background-color: #4fb128;
    border-color: #4fb128;
    transition: all 0.2s ease-in-out;
  }

  .actions button:disabled {
    cursor: no-drop;
    background-color: #4fb1284d;
    border: 1px solid #4fb1284d;
    transition: all 0.2s ease-in-out;
  }

  .actions2 {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .actions2 button {
    cursor: pointer;
    color: #4fb128;
    background-color: #E2FBD7;
    border: 1px solid #E2FBD7;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    width: 100%;
    height: 44px;
    transition: all 0.2s ease-in-out;
    padding: 1.2rem, 1.6rem, 1.2rem, 1.6rem;
  }

  .actions2 button:hover {
    background-color: #caefba;
    border-color: #caefba;
    transition: all 0.2s ease-in-out;
  }
}