@media only screen and (max-width: 912px) {
  .login {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .form {
    margin: 0px 24px;
  }

  .login__hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 50px;
  }

  .header p {
    margin-top: 13px;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 24px;
  }
  .forgotPassword {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .forgotPassword span a {
    text-decoration: none;
    color: #008069;
    display: flex;
    font-weight: 400;
    cursor: pointer;
    margin: 0px;
    font-size: 14px;
  }

  .login__options p {
    color: #3c3e3f;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    margin: 10px 0px;
    font-weight: 400;
    justify-content: flex-end;
  }

  .login__options span a {
    text-decoration: none;
    font-weight: 700;
    color: #008069;
    margin: 0px;
    margin-left: 10px;
  }
  .wrapper {
    width: 100%;
    margin-bottom: 40px;
  }
  .wrapper img {
    width: 100%;
    height: 100%;
  }
}
