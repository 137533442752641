.body{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.body p {
    margin: auto;
    margin-top: 90px;
    font-size: 25px;
}
